import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/Layout/Layout"

import Seo from "../../components/Seo"

export default function Confidentialite() {
  return (
    <Layout>
      <Seo title="Politique de confidentialité" index={false} follow={false} />
      <section className="container">
        <div className="shorten-text center-block justify">
          <div>
            <Link
              to="/privacy/en/"
              className="text-center"
              style={{ display: "block", marginTop: "2rem" }}
              title="Voir cette page en anglais"
            >
              🇬🇧
            </Link>
            {/* Données recueillies par ce site */}
            <h1 className="title text-center uppercase italic">
              Données recueillies par ce site
            </h1>

            <p>
              Lors de la navigation sur ce site, des logs d'accès sont collectés
              par notre hébergeur Netlify Inc., dont le siège social est situé
              au 2325 3rd Street, Suite 215, San Francisco, California 94107 aux
              États-Unis. Ces données sont conservées moins de 30 jours et ne
              sont pas utilisées à des fins marketing. Ces données se composent
              de votre adresse IP, du système d'exploitation de votre matériel
              et sa version, de votre navigateur web et sa version, de l'adresse
              URL consultée et de l'adresse URL de la page précédente (URL de
              référence).
            </p>
          </div>
          <div>
            <h2 className="title text-center uppercase italic">
              Responsable du traitement des données
            </h2>
            <ul>
              {/* Responsable du traitement des données */}
              <li>
                Responsable du traitement des données :<p>Pierre PHILIPPON</p>
              </li>
              <li>
                Adresse
                <p>136 rue de la Bruyère, 78300 POISSY</p>
              </li>
              <li>
                Email
                <p>
                  <a
                    href="mailto:pierre@urule.fr"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="legal-link_style"
                  >
                    pierre@urule.fr
                  </a>
                </p>
              </li>
              <li>
                Téléphone{" "}
                <p>
                  <a
                    href="tel:33637102262"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="legal-link_style"
                  >
                    +33 6 37 10 22 62
                  </a>
                </p>
              </li>
            </ul>
            <p>
              Les données collectées seront communiquées aux seuls destinataires
              suivants : Pierre Philippon, en tant que responsable du traitement
              des données.
            </p>
            <p>Elles sont conservées pendant 6 mois.</p>
            <p>
              Vous pouvez accéder aux données vous concernant, les rectifier,
              demander leur effacement ou exercer votre droit à la limitation du
              traitement de vos données. Vous pouvez retirer à tout moment votre
              consentement au traitement de vos données, vous opposer au
              traitement de vos données et exercer votre droit à la portabilité
              de vos données. Consultez le site{" "}
              <a
                href="http://www.cnil.fr"
                rel="noopener noreferrer"
                target="_blank"
                className="legal-link_style"
              >
                cnil.fr
              </a>{" "}
              pour plus d’informations sur vos droits.
            </p>
            <p>
              Pour exercer ces droits ou pour toute question sur le traitement
              de vos données dans ce dispositif, vous pouvez contacter le
              responsable du traitement des données désigné au paragraphe 2.
            </p>
            <p>
              Si vous estimez, après nous avoir contactés, que vos droits «
              Informatique et Libertés » ne sont pas respectés, vous pouvez
              adresser une réclamation à la CNIL.
            </p>

            {/* End of privacy
          -------------------------------------------------- */}
          </div>
          <Link to="/" className="accueil-link legal-link_style">
            Accueil
          </Link>
        </div>
      </section>
    </Layout>
  )
}
